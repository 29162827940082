import React, { useEffect } from 'react';

import Layout from '@layout/Layout';
import PageContainer from '@layout/PageContainer';
import Seo from '@components/Seo/Seo';
import Spinner from '@components/Spinner/Spinner';
import Newsletter from '@components/Newsletter/Newsletter';

import Img from '@static/img/famiglia-sempre.jpg';

import PageHeader from '@components/PageHeader/PageHeader';

const FamigliaSempre = ({ location }) => {
  const title = 'Unisciti a noi per aiutare famiglie bisognose in Kenya';
  const subtitle =
    'Sabato, <strong>10 ottobre alle ore 11</strong> faremo una lezione di <br/>Pilates "soft" aperta a tutti.<br/><br/>Il costo di <strong>5 euro</strong> a persona sarà devoluto all\'organizzazione no profit <strong>Famiglia Sempre</strong>, fondata e gestita da <strong>Gessica Vianello</strong>, che opera in Kenya.';

  useEffect(() => {
    if (window.Calendly) {
      setTimeout(() => {
        window.Calendly.initInlineWidget({
          url: `https://calendly.com/liveflow/famiglia-sempre?text_color=29252d&primary_color=ff976c`,
          parentElement: document.getElementById('calendly-inline-widget'),
          prefill: {},
          utm: {},
        });
      }, 200);
    }
  }, []);

  return (
    <Layout>
      <Seo title={title} description={subtitle} url={location.pathname} />
      <PageContainer>
        <PageHeader title={title} subtitle={subtitle} nomargin center />
        <img className="lezione-di-prova-img" src={Img} alt="Famiglia Sempre" />
        <div className="relative">
          <div className="absolute_spinner">
            <Spinner loading={true} />
          </div>
          <div
            id="calendly-inline-widget"
            className="calendly-inline-widget absolute_calendly"
            data-url="https://calendly.com/liveflow/famiglia-sempre?text_color=29252d&primary_color=ff976c"
            style={{ minWidth: '320px', height: '750px' }}
          ></div>
        </div>
        <Newsletter />
      </PageContainer>
    </Layout>
  );
};

export default FamigliaSempre;
